

const List = [
    { icon: 'home', label: 'Dashboard', name: "Home", path: '/',  component: () => import('../views/Home.vue'),},

    /*//Instituições
    { icon: 'briefcase', label: 'Instituições', name:"Instituições", path: '/instituicoes',  component: () => import('../views/Instituicoes/index.vue') },
    { show: false, name:"Instituições/Criar", path: '/instituicoes/criar',  component: () => import('../views/Instituicoes/create.vue') },
    { show: false, name:"Instituições/Editar", path: '/instituicoes/editar/:id?',  component: () => import('../views/Instituicoes/update.vue') },*/

    //Projects
    { icon: 'list', label: 'Projects', name:"Projects", path: '/projects',  component: () => import('../views/Projects/index.vue') },
    { show: false, name:"Projects/Create", path: '/projects/create',  component: () => import('../views/Projects/create.vue') },
    { show: false, name:"Projects/Edit", path: '/projects/edit/:id?',  component: () => import('../views/Projects/update.vue') },
    
    //Users
    { icon: 'user-list', label: 'Users', name:"Users", path: '/users',  component: () => import('../views/Users/index.vue')},
    { show: false, name:"Users/Create", path: '/users/create',  component: () => import('../views/Users/create.vue') },
    { show: false, name:"Users/Edit", path: '/users/edit/:id?',  component: () => import('../views/Users/update.vue') },
    
    //Records
    { show: false, name:"Records/Create", path: '/records/create',  component: () => import('../views/Records/create.vue') },
    { show: false, name:"Records/Edit", path: '/records/edit/:id?',  component: () => import('../views/Records/update.vue') },
    
    /*//Comentários
    { icon: 'comment', label: 'Comentários', name:"Comentários", path: '/comentarios',  component: () => import('../views/Comentarios/index.vue')},
    // { show: false, name:"Comentários/Criar", path: '/comentarios/criar',  component: () => import('../views/Comentarios/create.vue') },
    { show: false, name:"Comentários/Editar", path: '/comentarios/editar/:id?',  component: () => import('../views/Comentarios/update.vue') },*/

    //Modules
    { icon: 'layout-grid', label: 'Module', name:"Module", path: '/modules',  component: () => import('../views/Modules/index.vue') },
    { show: false, name:"Module/Create", path: '/modules/create',  component: () => import('../views/Modules/create.vue') },
    { show: false, name:"Module/Edit", path: '/modules/edit/:id?',  component: () => import('../views/Modules/update.vue') },

    //Label

    { icon: 'laptop', label: 'Labels', name:"Label", path: '/labels',  component: () => import('../views/Label/index.vue') },
    { show: false, name:"Labels/Create", path: '/labels/create',  component: () => import('../views/Label/create.vue') },
    { show: false, name:"Labels/Edit", path: '/labels/edit/:id?',  component: () => import('../views/Label/update.vue') },

    //Activity

    { icon: 'brackets', label: 'Activity', name:"Activity", path: '/activities',  component: () => import('../views/Activity/index.vue') },
    { show: false, name:"Activity/Create", path: '/activities/create',  component: () => import('../views/Activity/create.vue') },
    { show: false, name:"Activity/Edit", path: '/activities/edit/:id?',  component: () => import('../views/Activity/update.vue') },


    
    //categorias 

    { icon: 'menu-boxed', label: 'Categories', name:"Categories", path: '/categories',  component: () => import('../views/Category/index.vue') },
    { show: false, name:"Categories/Create", path: '/categories/create',  component: () => import('../views/Category/create.vue') },
    { show: false, name:"Categories/Edit", path: '/categories/edit/:id?',  component: () => import('../views/Category/update.vue') },



    //discipline

    
    { icon: 'menu-grid-o', label: 'Disciplines', name:"Disciplines", path: '/disciplines',  component: () => import('../views/Disciplines/index.vue') },
    { show: false, name:"Disciplines/Create", path: '/disciplines/create',  component: () => import('../views/Disciplines/create.vue') },
    { show: false, name:"Disciplines/Edit", path: '/disciplines/edit/:id?',  component: () => import('../views/Disciplines/update.vue') },

    //controls

    { icon: 'calendar', label: 'Controls', name:"Controls", path: '/controls',  component: () => import('../views/Controls/index.vue') },

    //status

    { icon: 'clipboard', label: 'Status', name:"Status", path: '/status',  component: () => import('../views/Status/index.vue') },
    {  show: false, name:"Status/Create", path: '/status/create',  component: () => import('../views/Status/create.vue') },
    { show: false, name:"Status/Edit", path: '/status/edit/:id?',  component: () => import('../views/Status/update.vue') },
    
]

export default List;